import { httpservice, Message } from 'src/app/commons/commons.module';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatAccordion } from '@angular/material/expansion';
import { RadniNalog } from '../_models/RadniNalozi/radninalog';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';


@Component({
  selector: 'radni-nalozi',
  templateUrl: './radni-nalozi.component.html',
  styleUrls: ['./radni-nalozi.component.css'],
  standalone: true,
  imports: [CommonModule, MatExpansionModule, MatCardModule, MatIconModule,MatButtonModule,MatInputModule],
})
export class RadniNaloziComponent implements OnInit {
  getservice: httpservice;
  lista: RadniNalog[];
  listazatvoreni: RadniNalog[];
  page: number;
  noviZahtjev: boolean;
  ispravakZahtjeva: boolean;
  ispravakNovog: boolean;
  currentItem: any;
  changelog: any;

  constructor(dataservice: httpservice, private router: Router) {
    this.getservice = dataservice;
    this.page = 1;
  }
  ngOnInit() {
    this.noviZahtjev = false;
    this.ispravakZahtjeva = false;
    this.ispravakNovog = false;
   
    this.UcitajOtvorene();
    this.UcitajZatvorene();
  }

  NoviZahtjev() {
    this.noviZahtjev = true;
  }
  IspraviZahtjev(id) {
    this.currentItem = id;
    this.ispravakZahtjeva = true;
  }
  IspraviNoviZahtjev(id) {
    this.currentItem = id;
    this.ispravakNovog = true;
  }


  addItem(newItem: RadniNalog) {
    this.noviZahtjev = false;
    if (newItem.id > 0) {
      this.UcitajOtvorene();
      }      
  }

  editItem(newItem: RadniNalog) {
    this.ispravakZahtjeva = false;   
      this.UcitajZatvorene();   
  }

  editnewItem(newItem: RadniNalog) {
    this.ispravakNovog = false;    
      this.UcitajOtvorene();    
  }
  



  UcitajOtvorene() {
    this.getservice.GetRequest<Message>("radninalozi/listActive").subscribe(result => {
      if (result.status === "success") {
        this.lista = result.data;
      }
    }, error => console.error('Get zahtjev', error));
  }
  UcitajZatvorene() {
    this.getservice.GetRequest<Message>("radninalozi/listClosed/?page=" + this.page).subscribe(result => {
      if (result.status === "success") {
        this.listazatvoreni = result.data;
      }
    }, error => console.error('Get zahtjev', error));
  }

  OsvjeziZatvorene() {
    this.page = 1;
    this.listazatvoreni = null;
    this.UcitajZatvorene();
  }


  UcitajJos() {
    this.page++;
    this.getservice.GetRequest<Message>("radninalozi/listClosed/?page=" + this.page).subscribe(result => {
      if (result.status === "success") {
        var i = 0;
        var lista = result.data as RadniNalog[];
        lista.forEach(element => { this.listazatvoreni.push(element); });
        //for (i = 0; i < lista.length; i++) {
        //  this.listazatvoreni.push(lista[i]);
        //}
      }
    }, error => console.error('Get zahtjev', error));
  }

}
