import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { LoginComponent } from './login/login.component';
import { UsersComponent } from './users/users.component';
import { UsersAddComponent } from './users/usersAdd.component';
import { FirmaComponent } from './firma/firma.component';
import { FirmaAddComponent } from './firma/firmaAdd.component';
import { KlijentComponent } from './Klijent/klijent.component';
import { KlijentAddComponent } from './Klijent/klijentAdd.component';
import { RequestsComponent } from './requests/requests.component';
import { RequestsAddComponent } from './requests/requestsAdd.component';
import { LicenseComponent } from './license/license.component';
import { LicenseEditComponent } from './license/licenseEdit.component';
import { VodomjerilaComponent } from './Komunalno/Vodomjerila/vodomjerila.component';
import { KorisnikComponent } from './Komunalno/Korisnik/korisnik.component';
import { StartComponent } from './Komunalno/SaldaKonti/Utuzivanje/Start/start.component';
import { DokumentVrstaComponent } from './Komunalno/SaldaKonti/Utuzivanje/DokumentVrsta/dokumentvrsta.component';
import { DokumentComponent } from './Komunalno/SaldaKonti/Utuzivanje/Dokument/dokument.component';
import { NastavakComponent } from './Komunalno/SaldaKonti/Utuzivanje/Nastavak/nastavak.component';
import { KontrolnaPlocaComponent } from './Komunalno/SaldaKonti/Utuzivanje/kontrolna-ploca/kontrolna-ploca.component';
import { IspisUtuzivanjeComponent } from './Komunalno/SaldaKonti/Utuzivanje/ispis/ispis-utuzivanje.component';
import { SaldaKorisnikComponent } from './Komunalno/SaldaKonti/Korisnik/salda-korisnik.component';
import { DokumentTekstIspisComponent } from './Komunalno/SaldaKonti/Utuzivanje/DokumentTekstIspis/dokument-tekst-ispis.component';
import { ChangeLogComponent } from './changelog/changelog.component';
import { UputeUtuzivanjeComponent } from './Komunalno/SaldaKonti/Utuzivanje/upute/upute-utuzivanje.component';
import { PocetnoStanjeComponent } from './Komunalno/SaldaKonti/pocetno-stanje/pocetno-stanje.component';
import { DataLogComponent } from './system/datalog/datalog.component';
import { PregledPrijedlogaComponent } from './Komunalno/SaldaKonti/Ovrhe/pregled-prijedloga/pregled-prijedloga.component';
import { ObustavakComponent } from './Komunalno/SaldaKonti/Utuzivanje/obustavak/obustavak.component';
import { PdvComponent } from './maticni/pdv/pdv.component';
import { PregledZahtjevaComponent } from './Komunalno/SaldaKonti/Ovrhe/pregled-zahtjeva/pregled-zahtjeva.component';
import { ApiTokenComponent } from './Komunalno/SaldaKonti/Ovrhe/api-token/api-token.component';
import { ZatvaranjeRacunaComponent } from './Komunalno/SaldaKonti/zatvaranje-racuna/zatvaranje-racuna.component';
import { TroskoviPrometComponent } from './financije/bi/troskovi/troskovi-promet/troskovi-promet.component';
import { TroskoviKarticaComponent } from './financije/bi/troskovi/troskovi-kartica/troskovi-kartica.component';
import { RadniNaloziComponent } from './RadniNalozi/radni-nalozi.component';



const appRoutes: Routes = [
    {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        component: LoginComponent
    },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'users/add', component: UsersAddComponent, canActivate: [AuthGuard] },
  { path: 'users/add/:id', component: UsersAddComponent, canActivate: [AuthGuard] },
  { path: 'fetch-data', component: FetchDataComponent, canActivate: [AuthGuard] },
  { path: 'firma', component: FirmaComponent, canActivate: [AuthGuard],},
  { path: 'firma/add', component: FirmaAddComponent, canActivate: [AuthGuard], },
  { path: 'firma/add/:id', component: FirmaAddComponent, canActivate: [AuthGuard] },
  { path: 'klijent', component: KlijentComponent, canActivate: [AuthGuard], },
  { path: 'klijent/add', component: KlijentAddComponent, canActivate: [AuthGuard], },
  { path: 'klijent/add/:id', component: KlijentAddComponent, canActivate: [AuthGuard] },
  { path: 'requests', component: RequestsComponent, canActivate: [AuthGuard], },
  { path: 'requests/add', component: RequestsAddComponent, canActivate: [AuthGuard], },
  { path: 'requests/add/:id', component: RequestsAddComponent, canActivate: [AuthGuard] },
  { path: 'license', component: LicenseComponent, canActivate: [AuthGuard], },
  { path: 'license/edit/:id', component: LicenseEditComponent, canActivate: [AuthGuard] },
  { path: 'komunalno/vodomjerila', component: VodomjerilaComponent, canActivate: [AuthGuard], },
  { path: 'komunalno/korisnik', component: KorisnikComponent, canActivate: [AuthGuard], },
  { path: 'komunalno/saldakonti/utuzivanje/start', component: StartComponent, canActivate: [AuthGuard], },
  { path: 'komunalno/saldakonti/utuzivanje/dokumentvrsta', component: DokumentVrstaComponent, canActivate: [AuthGuard], },
  { path: 'komunalno/saldakonti/utuzivanje/dokument', component: DokumentComponent, canActivate: [AuthGuard], },
  { path: 'komunalno/saldakonti/utuzivanje/nastavak', component: NastavakComponent, canActivate: [AuthGuard], },
  { path: 'komunalno/saldakonti/utuzivanje/obustavak', component: ObustavakComponent, canActivate: [AuthGuard], },
  { path: 'komunalno/saldakonti/utuzivanje/kontrolna-ploca', component: KontrolnaPlocaComponent, canActivate: [AuthGuard], },
  { path: 'komunalno/saldakonti/utuzivanje/ispis', component: IspisUtuzivanjeComponent, canActivate: [AuthGuard], },
  { path: 'komunalno/saldakonti/korisnik', component: SaldaKorisnikComponent, canActivate: [AuthGuard], },
  { path: 'komunalno/saldakonti/utuzivanje/dokumenttekst', component: DokumentTekstIspisComponent, canActivate: [AuthGuard], },
  { path: 'changelog', component: ChangeLogComponent, canActivate: [AuthGuard], },
  { path: 'komunalno/saldakonti/utuzivanje/upute', component: UputeUtuzivanjeComponent, canActivate: [AuthGuard], },
  { path: 'komunalno/saldakonti/pocetno-stanje', component: PocetnoStanjeComponent, canActivate: [AuthGuard], },
  { path: 'komunalno/saldakonti/utuzivanje/ovrhe/pregled-prijedloga', component: PregledPrijedlogaComponent, canActivate: [AuthGuard], },
  { path: 'komunalno/saldakonti/utuzivanje/ovrhe/pregled-zahtjeva', component: PregledZahtjevaComponent, canActivate: [AuthGuard], },
  { path: 'datalog', component: DataLogComponent, canActivate: [AuthGuard], },
  { path: 'maticni/pdv', component: PdvComponent, canActivate: [AuthGuard], },
  { path: 'komunalno/saldakonti/utuzivanje/ovrhe/apitoken', component: ApiTokenComponent, canActivate: [AuthGuard], },
  { path: 'komunalno/saldakonti/zatvaranje-racuna', component: ZatvaranjeRacunaComponent, canActivate: [AuthGuard], },
  { path: 'financije/bi/troskovi/troskovi-promet', component: TroskoviPrometComponent, canActivate: [AuthGuard], },
  { path: 'financije/bi/troskovi/troskovi-kartica', component: TroskoviKarticaComponent, canActivate: [AuthGuard], },
  { path: 'radninalozi/radni-nalozi', component: RadniNaloziComponent, canActivate: [AuthGuard], },
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes, { anchorScrolling: 'enabled' });

